<template>
  <div class="order_wrap">
    <div class="success">
      <img
        class="complete"
        src="@/assets/img/icon_success_red.svg"
        alt="Order Complete"
      />
      <h1>{{ this.$t("order.order_complete") }}</h1>
      <h2>
        {{ this.$t("order.order_no_is") }}
        <span class="number">{{ this.order_id }}</span>
      </h2>
      <p>{{ this.$t("order.order_complete_desc") }}</p>
      <button class="button" @click="click_history">
        {{ this.$t("order.go_to_history") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderComplete",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.order"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.order"),
      },
    };
  },
  data: function () {
    return {
      invoice_token: "",
      order_id: 0,
    };
  },
  mounted() {
    this.init_data();
  },
  methods: {
    init_data: function () {
      let self = this;

      if (typeof this.$route.query.order_id == "undefined") {
        this.$swal
          .fire({
            text: self.$t("common.error_invalid_access"),
            icon: "error",
          })
          .then(() => {
            location.href = "/";
          });

        return;
      }

      this.order_id = this.$route.query.order_id;
    },
    click_history: function () {
      this.$router.push("/mypage/order");
    },
  },
};
</script>

<style scoped>
.order_wrap {
  display: block;
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;

  background: #fff;
}

.order_wrap > .success {
  margin: 8rem 0;
  text-align: center;
}
.order_wrap > .success > img.complete {
  display: block;
  margin: 0 auto;
  width: 120px;
  max-width: 80vw;
}
.order_wrap > .success > h1 {
  font-size: 2.2rem;
  font-weight: 700;

  margin: 2rem 0 0.8rem 0;
  padding: 0;
}
.order_wrap > .success > h2 {
  font-size: 1.6rem;
  font-weight: 500;

  margin: 0 0 0.8rem 0;
  padding: 0;
}
.order_wrap > .success > h2 > .number {
  color: #e74356;
}
.order_wrap > .success > p {
  font-size: 1.1rem;
  font-weight: 400;
  color: #808080;

  margin: 0 0 3rem 0;
  padding: 0;
}
</style>
